<template>
    <LinkBlockHighlight
        :header="header"
        :background="background"
        :image="image"
        :link="link"
        :text="text"
    />
</template>

<script>
import { get } from 'lodash-es';

export default {
    props: {
        content: {
            type: Object,
            required: true,
            default: () => { return {}; }
        }
    },
    computed: {
        background() {
            return get(this, 'content.backgroundColorLight', '');
        },
        header() {
            return get(this, 'content.header', '');
        },
        image() {
            return get(this, 'content.image.0', {});
        },
        link() {
            return get(this, 'content.linkSingle.0', {});
        },
        text() {
            return get(this, 'content.richTextSimple', '');
        }
    }
};
</script>
