<template>
    <BaseSection
        width="page"
        :background="'none'"
    >
        <TextImage
            v-if="content"
            :image="image"
            :rich-text="text"
            :rounded="rounded"
        />
    </BaseSection>
</template>

<script>
export default {
    props: {
        content: {
            type: Object,
            required: true,
            default: () => { return {}; }
        }
    },

    computed: {
        image() {
            return this?.content?.image?.[0] ?? null;
        },
        text() {
            return this?.content?.richText ?? null;
        },
        rounded() {
            return this?.content?.rounded ?? false;
        }
    }
};
</script>
