<template>
    <BaseSection
        id="contact-block"
        width="page"
        class="section--form"
    >
        <div class="section--form__wrapper background--brown">
            <h2 class="section--form__title">{{ content?.title }}</h2>
            <p class="section--form__description">{{ content?.description }}</p>

            <FormieForm
                :form-handle="content?.form?.[0]?.handle"
                type="formie"
            />
        </div>
    </BaseSection>
</template>

<script>
export default {
    props: {
        content: {
            type: Object,
            required: true
        }
    }
};
</script>
