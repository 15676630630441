<template>
    <BaseSection
        v-if="profileCards.length"
        class="section--profile-cards-small"
        :class="largeHeader ? 'section--large-header' : ''"
        width="content"
    >
        <template
            v-if="header"
            v-slot:title
        >
            {{ header }}
        </template>
        <template
            v-if="description"
            v-slot:description
        >
            <div v-html="description" /> <!-- eslint-disable-line -->
        </template>
        <ProfileCards :links="profileCards" />
    </BaseSection>
</template>

<script>
import { get } from 'lodash-es';

export default {
    props: {
        content: {
            type: Object,
            required: true,
            default: () => { return {}; }
        }
    },
    computed: {
        background() {
            return get(this, 'content.backgroundColorLight', '');
        },
        header() {
            return get(this, 'content.header', '');
        },
        largeHeader() {
            return get(this, 'content.largeHeader', true);
        },
        description() {
            return get(this, 'content.richTextSimple', '');
        },
        profileCards() {
            return get(this, 'content.profileCard', []);
        }
    }
};
</script>
